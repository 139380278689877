/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'qLFHt22upVYY9VnsbU3C9A.png': { uri: '/images/qLFHt22upVYY9VnsbU3C9A.png' },
'vReYmE5uUJrJ1yrGd51inR.png': { uri: '/images/vReYmE5uUJrJ1yrGd51inR.png' },
'pREVQNaWuh1fgr47XaCPfY.png': { uri: '/images/pREVQNaWuh1fgr47XaCPfY.png' },
'38aicfzgTBJTwv6yog6BFA.png': { uri: '/images/38aicfzgTBJTwv6yog6BFA.png' },
'ifURy3WC22g3z5m6L9ZFtn.png': { uri: '/images/ifURy3WC22g3z5m6L9ZFtn.png' },
'ca23Fnn3dmafUxhHZhJbxw.png': { uri: '/images/ca23Fnn3dmafUxhHZhJbxw.png' },
'4vAr5AkBWeWzBDdC8yw6DY.png': { uri: '/images/4vAr5AkBWeWzBDdC8yw6DY.png' },
'2MMUQfmwMwMBRdXgoSnckM.png': { uri: '/images/2MMUQfmwMwMBRdXgoSnckM.png' },
'mHNW9STb2gRLHusbT7iYMK.png': { uri: '/images/mHNW9STb2gRLHusbT7iYMK.png' },
'o2dhGjyZmvsjGDn9hP1A2u.png': { uri: '/images/o2dhGjyZmvsjGDn9hP1A2u.png' },
'p1Xgv5BHQU7YHnuknAQpsw.png': { uri: '/images/p1Xgv5BHQU7YHnuknAQpsw.png' },
'dCWbik5s2qXYaVUDqpgct4.png': { uri: '/images/dCWbik5s2qXYaVUDqpgct4.png' },
'61Zx7U6WT5TizBMcDQkPzE.jpg': { uri: '/images/61Zx7U6WT5TizBMcDQkPzE.jpg' },
'kp2fnHVJgWrLcRU81c9PWW.jpg': { uri: '/images/kp2fnHVJgWrLcRU81c9PWW.jpg' },
'jZEvszbS532dsBjeAW5QY3.jpg': { uri: '/images/jZEvszbS532dsBjeAW5QY3.jpg' },
'sWL3XjUsRXe8atSXWAMo6t.png': { uri: '/images/sWL3XjUsRXe8atSXWAMo6t.png' },
'g67F7D2Soe1LbjLWy2kLKp.jpg': { uri: '/images/g67F7D2Soe1LbjLWy2kLKp.jpg' },
'qpooWB7qPYMLJECjX72Jcj.png': { uri: '/images/qpooWB7qPYMLJECjX72Jcj.png' },
'bLGLf7MrgY8yc2hZJMKRon.jpg': { uri: '/images/bLGLf7MrgY8yc2hZJMKRon.jpg' }
}

export default imageStaticSourcesByFileName
